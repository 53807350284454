import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const StyledBox = styled(Box)(({ theme }) => ({
  "& .LPFlink": {
    color: theme.palette.common.fogWhite,
    fontWeight: 400,
    textTransform: "none",
  },
  "& .LPFContact": {
    flexDirection: "row",
    gap: theme.spacing(0.5),
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      "& .LPFlink": {
        padding: theme.spacing(0.5),
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

export default StyledBox;
