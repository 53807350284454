import React from "react";
import StyledBox, {
  StyledImgBox,
} from "views/HomeView/components/SampleInventorySection/SampleInventorySection.styles";
import { useTranslation } from "next-i18next";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Stack,
  Box,
} from "@mui/material";
import { Typography500, Typography700 } from "views/common/components/UI";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import StarsSharpIcon from "@mui/icons-material/StarsSharp";
import Grid from "@mui/material/Grid2";

const SampleInventorySection = () => {
  const { t } = useTranslation("home");
  // data
  const inventoryItems = [
    {
      src: "/erdx/images/2023 Harley-Davidson Road Glide Touring Edition.png",
      title: "2023 Harley-Davidson Road Glide Touring Edition",
      miles: "24,057",
      conditionScore: "83",
      price: "$15,700",
    },
    {
      src: "/erdx/images/2022 Harley-Davidson Road King.png",
      title: "2022 Harley-Davidson Road King",
      miles: "18,020",
      conditionScore: "80",
      price: "$11,200",
    },
    {
      src: "/erdx/images/2023 Harley-Davidson Street Glide.png",
      title: "2023 Harley-Davidson Street Glide",
      miles: "22,234",
      conditionScore: "82",
      price: "$14,000",
    },
    {
      src: "/erdx/images/2023 Harley-Davidson Ultra Limited.png",
      title: "2023 Harley-Davidson Ultra Limited",
      miles: " 24,304",
      conditionScore: "80",
      price: "$17,000",
    },
  ];
  return (
    <StyledBox>
      <Container maxWidth="xl">
        <Typography700
          textAlign="center"
          variant="h3"
          component="h2"
          color="common.fogWhite"
          mb={6}
        >
          {t("home:sample_inventory_preview")}
        </Typography700>
        <Grid container spacing={{ sm: 3, md: 2, lg: 2, xl: 3 }}>
          {inventoryItems.map((item, index) => (
            <Grid size={{ sm: 12, md: 6, lg: 3 }} key={index}>
              <Card className="SISCard">
                <Box className="SISCardContainer">
                  <StyledImgBox
                    image={{
                      src: item.src,
                      alt: t("home:erdx_logo"),
                    }}
                    imgheight="auto"
                    imgwidth="100%"
                    objectFit="contain"
                    unoptimized={true}
                  />
                  <CardContent>
                    <Typography500
                      component="p"
                      variant="h5"
                      gutterBottom
                      color="common.white"
                    >
                      {item.title}
                    </Typography500>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      mt={2}
                      spacing={1}
                    >
                      <Box>
                        <Stack direction="row" gap={0.5} alignItems="center">
                          <SpeedOutlinedIcon className="SISCardIcon" />
                          <Typography variant="body2">
                            {t("home:miles")}:
                          </Typography>
                          <Typography500 variant="body2">
                            {item.miles}
                          </Typography500>
                        </Stack>
                        <Stack
                          direction="row"
                          gap={0.5}
                          alignItems="center"
                          flexWrap="wrap"
                        >
                          <StarsSharpIcon className="SISCardIcon" />
                          <Typography variant="body2">
                            {t("home:condition_score")}:
                          </Typography>
                          <Typography500 variant="body2">
                            {item.conditionScore}
                          </Typography500>
                        </Stack>
                      </Box>
                      <Box>
                        <Typography variant="body2">
                          {t("home:our_price")}
                        </Typography>
                        <Typography700
                          variant="h4"
                          component="p"
                          color="primary"
                        >
                          {item.price}
                        </Typography700>
                      </Box>
                    </Stack>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </StyledBox>
  );
};

export { SampleInventorySection };
