import React from "react";
import StyledBox from "views/HomeView/components/LandingPageFooter/LandingPageFooter.styles";
import { useTranslation } from "next-i18next";
import { Container, Stack, Typography, Button } from "@mui/material";
import { Typography500, ImgBox } from "views/common/components/UI";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import NavigatorLink from "views/common/components/Logical/NavigatorLink/NavigatorLink";
import erLogo from "public/images/spinner-logo.png";

const LandingPageFooter = (props) => {
  const { t } = useTranslation("home");
  const { phone, email } = props;

  return (
    <StyledBox textAlign="center" py={4}>
      <Container maxWidth="xl">
        <Stack spacing={2} alignItems="center" justifyContent="center">
          <ImgBox
            image={{
              src: erLogo,
              alt: t("home:erdx_logo"),
            }}
            imgheight="60px"
            imgwidth="180px"
            objectFit="contain"
          />
          <Typography500 variant="h3" component="h2">
            {t("home:for_more_information")}
          </Typography500>
          <Typography variant="body1" component="p">
            {t("home:contact_the_erdx_team")}
          </Typography>
        </Stack>
        <Stack className="LPFContact">
          <Button
            variant="text"
            component={NavigatorLink}
            href={`tel:${phone}`}
            startIcon={<PhoneIcon />}
            className="LPFlink"
          >
            {phone}
          </Button>
          <Button
            variant="text"
            startIcon={<EmailIcon />}
            className="LPFlink"
            href={`mailto:${email}`}
          >
            {email}
          </Button>
        </Stack>
      </Container>
    </StyledBox>
  );
};

export { LandingPageFooter };
