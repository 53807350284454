import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { ImgBox } from "views/common/components/UI";

const StyledBox = styled(Box)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  "& .primary": {
    color: theme.palette.primary.main,
  },
  "& .MASImageGrid": {
    [theme.breakpoints.down("lg")]: {
      width: `calc(100% + ${theme.spacing(6)})`,
      marginBottom: theme.spacing(3),
      marginLeft: theme.spacing(-3),
      marginRight: theme.spacing(-3),
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: theme.spacing(81),
    },
  },
  "& .MASImage": {
    [theme.breakpoints.up("lg")]: {
      position: "absolute",
      width: "calc(50% - 16px)",
      top: 0,
      left: 0,
    },
  },
  "& .MASItemNumber": {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(5),
    width: theme.spacing(5),
    minWidth: theme.spacing(5),
    height: theme.spacing(5),
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    lineHeight: theme.spacing(5),
  },
}));
export const StyledImgBox = styled(ImgBox)(({ theme }) => ({
  "&.IBimgbox": {
    [theme.breakpoints.down("lg")]: {
      height: theme.spacing(50),
    },
    [theme.breakpoints.down("md")]: {
      height: theme.spacing(25),
    },
  },
}));
export default StyledBox;
