import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { ImgBox } from "views/common/components/UI";

const StyledBox = styled(Box)(({ theme }) => ({
  "& .WEItemHeader": {
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
  },
  "& .WEItemText ul": {
    listStylePosition: "inside !important",
    padding: 0,
  },
  "& .WEItemText ul ul": {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    "& li": {
      marginBottom: "0 !important",
    },
  },
  "& .WEItemText .WEItemTextList": {
    marginTop: theme.spacing(3),
    marginBottom: 0,
    listStyle: "decimal",
    fontWeight: 500,
    "& li": {
      marginBottom: theme.spacing(1),
    },
  },
}));

export const StyledImgBox = styled(ImgBox)(({ theme }) => ({
  "&.IBimgbox": {
    [theme.breakpoints.down("md")]: {
     width: theme.spacing(8),
    },
  },
}));
export default StyledBox;
