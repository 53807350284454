import { styled, alpha } from "@mui/material/styles";
import { Box } from "@mui/material";
import { ImgBox } from "views/common/components/UI";

const StyledBox = styled(Box)(({ theme }) => ({
  "& .SISCard": {
    backgroundColor: `${alpha(theme.palette.grey[600], 0.7)}`,
    color: theme.palette.grey[100],
    height: "100%",
    transition: "0.3s",
    border: `1px solid ${alpha(theme.palette.grey[600], 0.7)}`,

    "& .SISCardContainer ": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",

      "& .MuiCardContent-root": {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "space-between",
        height: "auto",
        flex: 1,
      },
    },
    "& .SISCardIcon": {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));
export const StyledImgBox = styled(ImgBox)(({ theme }) => ({
  "&.IBimgbox": {
    height: "auto",
    "& img": {
      height: "auto",
      position: "relative !important",
    },
  },
}));
export default StyledBox;
