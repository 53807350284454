import React, { useState } from "react";
import { Button, Box, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import {
  CommonOverlayLayout,
  LightTextField,
} from "views/common/components/UI";

const ForgotPasswordDialog = (props) => {
  // props
  const { open, handleClose, handleResetPassword, errorMsgs, setErrorMsgs } =
    props;

  // init
  const [emailValue, setEmailValue] = useState("");

  const { t } = useTranslation("fe_rm_common_enter_password_overlay");

  const handleInputChange = (e) => {
    setEmailValue(e.target.value);
  };
  const handleResetPasswordClick = () => {
    if (!emailValue) {
      setErrorMsgs((prev) => ({
        ...prev,
        email: t("home:email_required"),
      }));
    } else {
      handleResetPassword(emailValue);
    }
  };
  return (
    <CommonOverlayLayout
      open={open}
      fullScreen={false}
      handleClose={handleClose}
      maxWidth="md"
      title={t("home:forgot_password")}
      dialogActions={
        <>
          <Button color="secondary" onClick={handleClose}>
            {t("home:cancel")}
          </Button>
          <Button variant="contained" onClick={handleResetPasswordClick}>
            {t("home:reset_password")}
          </Button>
        </>
      }
    >
      <Box>
        <Typography
          variant="body1"
          component="p"
          color="secondary.light"
          mb={2}
        >
          {t("home:enter_your_email_address")}
        </Typography>
        <LightTextField
          required
          label={t("home:email")}
          fullWidth
          onChange={handleInputChange}
          error={Boolean(errorMsgs.email)}
          helperText={errorMsgs.email}
          value={emailValue}
        />
      </Box>
    </CommonOverlayLayout>
  );
};

export { ForgotPasswordDialog };
