import React from "react";
import StyledBox, {
  StyledImgBox,
} from "views/HomeView/components/WhyErdxSection/WhyErdxSection.styles";
import { useTranslation } from "next-i18next";
import { Container, Typography, Stack } from "@mui/material";
import { Typography700 } from "views/common/components/UI";
import Grid from "@mui/material/Grid2";

const WhyErdxSection = () => {
  const { t } = useTranslation("home");

  // data
  const whyErdxItems = [
    {
      title: t("home:convenient"),
      src: "/erdx/Icons/Convenient_icon.png",
      text: t("home:convenient_text"),
    },
    {
      title: t("home:trusted"),
      src: "/erdx/Icons/Trusted _icon.png",
      text: t("home:trusted_text"),
    },
    {
      title: t("home:cost_effective"),
      src: "/erdx/Icons/Cost_Effective_icon.png",
      text: t("home:cost_effective_text"),
    },
  ];
  return (
    <StyledBox>
      <Container maxWidth="xl">
        <Typography700
          textAlign="center"
          variant="h3"
          component="h2"
          color="common.fogWhite"
          mb={6}
        >
          {t("home:why_choosing_erdx")}
        </Typography700>
        <Grid container spacing={8}>
          {whyErdxItems.map((item, index) => (
            <Grid size={{ md: 12, lg: 4 }} key={index}>
              <Stack
                direction="row"
                gap={2}
                alignItems="center"
                className="WEItemHeader"
                pb={2}
              >
                <StyledImgBox
                  image={{
                    src: item.src,
                    alt: t("home:erdx_logo"),
                  }}
                  imgheight="64px"
                  imgwidth="64px"
                  objectFit="contain"
                />
                <Typography700 component="p" variant="h3">
                  {item.title}
                </Typography700>
              </Stack>

              <Typography
                component="p"
                variant="body1"
                className="WEItemText"
                dangerouslySetInnerHTML={{
                  __html: item.text,
                }}
              ></Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
    </StyledBox>
  );
};

export { WhyErdxSection };
