import { styled, alpha } from "@mui/material/styles";
import { Box } from "@mui/material";
import { ImgBox } from "views/common/components/UI";

const StyledBox = styled(Box)(({ theme }) => ({
  "& .HSLinks": {
    textDecoration: "underline",
    fontWeight: "bold",
    [theme.breakpoints.up("lg")]: {
      fontSize: theme.typography.pxToRem(18),
    },
    "& svg": { fontSize: theme.typography.pxToRem(24) },
    "&.HSPhone": {
      color: theme.palette.common.fogWhite,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  "& .HSDivider": {
    border: `1px solid ${theme.palette.grey[300]}`,
    alignSelf: "center",
    height: theme.spacing(3),
  },
  "& .HSTitle": {
    color: theme.palette.common.fogWhite,
    maxWidth: theme.typography.pxToRem(750),
    textAlign: "center",
  },
  "& .HSBrandLogo": {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  "& .HSContainer": {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: "1",
  },
  "& .HSSignFormContainer": {
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: theme.typography.pxToRem(1000),
    },
  },
  "& .HSSignForm": {
    backgroundColor: `${alpha(theme.palette.common.black, 0.6)}`,

    "& .HSSignFormAction": {
      height: "100%",
      [theme.breakpoints.up("lg")]: {
        width: theme.typography.pxToRem(143),
      },
    },
    "& .HSTextField": {
      border: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: theme.spacing(0.5),

      "& .MuiFormLabel-root": {
        color: theme.palette.grey[200],
      },
      "& .MuiInputBase-input": {
        color: theme.palette.grey.A000,
      },
    },
  },

  "& .HSCheckbox": {
    color: theme.palette.common.white,
    width: "42.2%",
    [theme.breakpoints.down("md")]: {
      width: "auto",
      marginRight: theme.spacing(0.5),
    },
    "& .MuiCheckbox-root": {
      padding: theme.spacing(0, 1),
    },
    "& .Mui-checked .MuiSvgIcon-root": {
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.common.white,
    },
  },
  "& .HSBrandLogos": {
    [theme.breakpoints.down("lg")]: {
      width: theme.spacing(8.125),
      height: theme.spacing(6.25),
    },
  },
  "& .HSForgetAndRemberContainer": {
    [theme.breakpoints.down("lg")]: {
      justifyContent: "space-between",
    },
  },
  "& .HSForgetPassLink": {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
export const StyledImgBox = styled(ImgBox)(({ theme }) => ({
  "&.IBimgbox": {
    [theme.breakpoints.down("lg")]: {
      width: theme.spacing(38),
    },
  },
}));
export default StyledBox;
