import React from "react";
import StyledBox from "views/HomeView/components/AskAboutSection/AskAboutSection.styles";
import { useTranslation } from "next-i18next";
import { Container, Stack } from "@mui/material";
import { Typography700, ImgBox } from "views/common/components/UI";

const AskAboutSection = () => {
  const { t } = useTranslation("home");

  return (
    <StyledBox textAlign="center" p={6}>
      <Container maxWidth="xl">
        <Stack
          direction={{ sm: "column", md: "column", lg: "row" }}
          spacing={4}
          rowSpacing={4}
          alignItems="center"
          justifyContent="center"
        >
          <ImgBox
            image={{
              src: "/erdx/Icons/72-hour.png",
              alt: t("home:erdx_logo"),
            }}
            imgheight="55px"
            imgwidth="100px"
            objectFit="contain"
          />
          <Typography700 variant="h3" component="h2">
            {t("home:ask_about_our_72hour_satisfaction_guaranteed")}
          </Typography700>
        </Stack>
      </Container>
    </StyledBox>
  );
};

export { AskAboutSection };
