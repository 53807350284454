import React, { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import StyledBox from "./HomeView.styles";
import {
  HeroSection,
  AboutSection,
  WhyErdxSection,
  SampleInventorySection,
  MotorcycleAvailabilitySection,
  AskAboutSection,
  LandingPageFooter,
} from "views/HomeView/components";
import { Box } from "@mui/material";
import axios, { API } from "lib/config/axios";
import { useErrorsHandler } from "views/common/hooks";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setSnackbar } from "reduxStore/features/ui-slice";
import useAppNavigator from "views/common/hooks/useAppNavigator";
import PATHS from "lib/navigation/paths";
import dynamic from "next/dynamic";
import { CONTACTS } from "views/common/utils/constants";

const ForgotPasswordDialog = dynamic(() =>
  import("views/HomeView/components").then((file) => file.ForgotPasswordDialog)
);
const { envVars } = require("next/config").default().publicRuntimeConfig;

const HomeView = () => {
  const { t } = useTranslation("home");
  const appState = useSelector((state) => state.globalData.appState);
  const authenticityToken = appState.authenticityToken;
  const appNavigator = useAppNavigator();
  const dispatch = useDispatch();
  const handleErrorResponse = useErrorsHandler();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    remember_me: false,
  });
  const [forgetPassDialog, setForgetPassDialog] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState({});

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleRememberMeCheckBox = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };
  const handleForgetPassClickBtn = (e) => {
    setForgetPassDialog(true);
  };
  const handleCloseDialog = () => {
    setForgetPassDialog(false);
  };

  const validateFormData = (data) => {
    let errors = {};

    if (!data.email) {
      errors.email = t("home:email_required");
    }
    if (!data.password) {
      errors.password = t("home:password_required");
    }

    return errors;
  };

  const handleSignIn = () => {
    setErrorMsgs({}); // Clear previous errors
    localStorage.clear();

    const errors = validateFormData(formData);
    if (Object.keys(errors).length > 0) {
      const errorMessage = Object.values(errors).join(", ");
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: errorMessage,
        })
      );
      return;
    }
    dispatch(setLoading(true));
    axios
      .post(
        API.users.signIn,
        {
          authenticity_token: authenticityToken,
          user: { ...formData },
        },
        { baseURL: envVars.ER_WEBSITE_BASE }
      )
      .then((res) => {
        if (res.data.access_token) {
          localStorage.setItem("signin", true);
          localStorage.setItem("signin-msg", res.data.message);
          if (typeof ReactNativeWebView !== "undefined") {
            // eslint-disable-next-line no-undef
            ReactNativeWebView.postMessage(
              JSON.stringify({
                type: "login",
                data: res.data,
              })
            );
          }
          appNavigator.reload();
        } else {
          dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: t("common_layout:errors.not_authorized"),
            })
          );
        }
      })
      .catch((error) => {
        try {
          switch (error.response.status) {
            case 401:
              if (error.response.data.error_code === "ERG002") {
                axios.get(API.users.signOut);
              }
              dispatch(
                setSnackbar({
                  open: true,
                  severity: "error",
                  message: error.response.data.message,
                })
              );
              break;
            default:
              handleErrorResponse(error);
          }
        } catch (e) {
          handleErrorResponse(error);
        }
        dispatch(setLoading(false));
      });
  };

  const handleResetPassword = async (emailValue) => {
    setErrorMsgs({});
    try {
      const { data } = await axios.post(
        API.users.forgotPassword,
        {
          authenticity_token: authenticityToken,
          user: { email: emailValue },
        },
        { baseURL: envVars.ER_WEBSITE_BASE }
      );
      dispatch(
        setSnackbar({
          open: true,
          severity: "success",
          message: data.message,
        })
      );
      handleCloseDialog();
    } catch (error) {
      if (error.response && error.response.status && error.response.data) {
        switch (error.response.status) {
          case 406:
            if (error.response.data.message) {
              dispatch(
                setSnackbar({
                  open: true,
                  severity: "error",
                  message: error.response.data.message,
                })
              );
            }
            if (error.response.data.errors) {
              setErrorMsgs({
                ...error.response.data.errors,
              });
            }
            break;
          default:
            handleErrorResponse(error);
        }
      }
    }
  };

  useEffect(() => {
    const currentUser = appState?.currentUser;
    if (!currentUser) return;

    const { privileged, can_view_erdx, user_companies_count, dealer_request } =
      currentUser;

    if (privileged && can_view_erdx) {
      appNavigator.push(PATHS.vehicles.index());
    } else if (user_companies_count <= 0 || !dealer_request) {
      appNavigator.push(user_companies_count <= 0 ? "/" : PATHS.signup());
    } else {
      appNavigator.push("/");
    }
  }, [appState?.currentUser]);

  return (
    <>
      {!(
        appState?.currentUser?.isPrivileged &&
        appState?.currentUser?.can_view_erdx
      ) && (
        <StyledBox>
          <HeroSection
            handelSignInBtnClick={handleSignIn}
            handleInputChange={handleInputChange}
            formData={formData}
            handleRememberMeCheckBox={handleRememberMeCheckBox}
            handleForgetPassClickBtn={handleForgetPassClickBtn}
            phone={CONTACTS.phone}
          />
          {forgetPassDialog && (
            <ForgotPasswordDialog
              open={forgetPassDialog}
              handleClose={handleCloseDialog}
              handleResetPassword={handleResetPassword}
              errorMsgs={errorMsgs}
              setErrorMsgs={setErrorMsgs}
            />
          )}
          <Box className="DVPageContent">
            <Box className="DVDividerGredient"></Box>
            <Box my={{ sm: 8, lg: 15 }}>
              <AboutSection />
            </Box>
            <Box my={{ sm: 8, lg: 15 }}>
              <WhyErdxSection />
            </Box>
            <Box my={{ sm: 8, lg: 15 }}>
              <SampleInventorySection />
            </Box>
            <Box my={{ sm: 8, lg: 15 }}>
              <MotorcycleAvailabilitySection />
            </Box>
            <AskAboutSection />
            <Box className="DVDividerGredient"></Box>
            <LandingPageFooter phone={CONTACTS.phone} email={CONTACTS.email} />
          </Box>
        </StyledBox>
      )}
    </>
  );
};

export default HomeView;
