import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const StyledBox = styled(Box)(({ theme }) => ({
  "& .DVPageContent": {
    backgroundColor: theme.palette.grey["900"],
    color: theme.palette.common.fogWhite,
  },
  "& .DVDividerGredient": {
    height: theme.spacing(0.75),
    width: "100%",
    background:
      "linear-gradient(90deg, rgba(254, 123, 16, 0.00) 2.18%, #EDC14F 52.18%, rgba(254, 123, 16, 0.00) 102.18%)",
  },
}));

export default StyledBox;
