import React from "react";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { defaultNS } from "next-i18next.config";
import HomeView from "views/HomeView/HomeView";

const Home = () => {
  return <HomeView />;
};
export async function getServerSideProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        ...defaultNS,
        "home",
        "common_layout",
      ])),
      hideLayout: true,
      skipRedirect: true,
    },
  };
}
export default Home;
